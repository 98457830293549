<template>
  <svg
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.7392 6.90552C19.3893 6.90552 17.4776 8.81643 17.4776 11.1663C17.4776 11.5592 17.5482 11.933 17.6488 12.2944L15.9412 14.9889C15.7148 14.9367 15.4815 14.9022 15.2406 14.9022C14.4862 14.9022 13.8024 15.1838 13.2644 15.6343L8.17325 13.4003C8.17708 13.3397 8.1909 13.2837 8.1909 13.223C8.1909 11.5163 6.80107 10.128 5.09507 10.128C3.38906 10.128 2 11.5155 2 13.223C2 14.9306 3.38906 16.3189 5.09507 16.3189C5.7305 16.3189 6.32143 16.127 6.81259 15.7963L12.1585 18.1431C12.2368 19.7815 13.5821 21.0946 15.2406 21.0946C16.9473 21.0946 18.3356 19.7056 18.3356 17.9988C18.3356 17.8706 18.3141 17.7478 18.2973 17.6235L21.1644 15.3703C21.3532 15.3972 21.542 15.4287 21.7384 15.4287C24.0883 15.4287 26 13.517 26 11.1671C26 8.81566 24.0883 6.90552 21.7392 6.90552ZM3.64385 13.223C3.64385 12.4234 4.2954 11.7718 5.09507 11.7718C5.8955 11.7718 6.54705 12.4226 6.54705 13.223C6.54705 14.0235 5.8955 14.6743 5.09507 14.6743C4.2954 14.6743 3.64385 14.0235 3.64385 13.223ZM15.2406 19.4991C14.4117 19.4991 13.7379 18.8261 13.7379 17.9973C13.7379 17.1692 14.4117 16.4939 15.2406 16.4939C16.0686 16.4939 16.7424 17.1684 16.7424 17.9973C16.7424 18.8261 16.0686 19.4991 15.2406 19.4991ZM21.7392 13.6006C20.3962 13.6006 19.3049 12.5086 19.3049 11.1663C19.3049 9.82407 20.3962 8.73201 21.7392 8.73201C23.0822 8.73201 24.1735 9.82407 24.1735 11.1663C24.1735 12.5086 23.0822 13.6006 21.7392 13.6006Z"
      fill="#F5F5FA"
    />
  </svg>
</template>
